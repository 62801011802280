@import "styles/_variables.scss";

$card-shadow-size: 8px;

.heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;

  h2 {
    margin: 0;
  }

  > :last-child {
    margin-left: auto;
  }
}

.carousel {
  display: flex;
  flex-direction: row;

  padding: $card-shadow-size 0;
  margin: (24px - $card-shadow-size) ($spacing--mobile * -1);

  overflow-x: auto;
  overscroll-behavior: contain;
  scroll-snap-type: x mandatory;
}

.horse {
  flex: 0 0 100%;

  display: flex;
  padding: 0 ($spacing--mobile / 2);
  box-sizing: border-box;

  list-style-type: none;
  scroll-snap-align: start;
}

.card {
  flex: 1 1 0;
  margin: 0 ($spacing--mobile / 2);
}

// Hack to hide scrollbar in various browsers
.carousel {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.carousel::-webkit-scrollbar {
  display: none;
}

.indicator {
  display: flex;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  line-height: 0;
  font-size: 0;
}

.dot-wrapper {
  flex: 0 1 8px;
  margin: 0 4px;

  &--clickable {
    flex: 0 1 32px;
    cursor: pointer;
  }
}

.dot {
  display: inline-block;
  width: 100%;
  height: 4px;
  margin: 8px 0;
  border-radius: 2px;
  background: $alto;

  &--active {
    background: $deep-cerulean;
  }
}

// DESKTOP OVERRIDES
@media only screen and (min-width: $screen__lg) {
  .carousel {
    margin: (24px - $card-shadow-size) ($spacing--desktop * -1);
  }

  .horse {
    padding: 0 ($spacing--desktop / 2);
  }

  .horse:last-child::after {
    width: $spacing--desktop;
  }

  .card {
    margin: 0 ($spacing--desktop / 2);
  }
}
