@import "styles/_variables.scss";

.content {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  color: $dove-gray;
  flex-grow: 1;
  margin: 24px 0;

  & strong {
    text-transform: capitalize;
  }
}

.button-row {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;

  & button {
    margin-left: 8px;
  }
}

.close-button {
  padding: 0;
  width: 24px;
  height: 24px;
  align-self: center;
}
