@import "styles/_variables.scss";

.all-dates {
  &__header {
    color: $mine-shaft;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.4;

    &--mobile {
      font-size: 16px;
      font-weight: normal;
    }
  }

  &__fixed-pane {
    &--mobile {
      padding: 0;
    }
  }
}
