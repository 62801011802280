@import "styles/_variables.scss";

// MOBILE DEFAULTS
.icon-frame {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-image: linear-gradient($deep-cerulean, $moss-green);
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 24px;
  height: 24px;
  fill: white;
}

// DESKTOP OVERRIDES
@media only screen and (min-width: $screen__lg) {
  .icon {
    width: 32px;
    height: 32px;
  }

  .icon-frame {
    width: 48px;
    height: 48px;
  }
}
