@import "styles/_variables.scss";

.application-access-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .icon {
    width: 288.3px;
    height: 199px;
    margin: 0 48px 24px;
    object-fit: contain;
  }

  .head {
    width: 288px;
    text-align: center;
    font-weight: 600;
    margin: 0 0 8px;
  }

  .context {
    margin: 8px 0 0;
    color: $dove-gray;
    width: 288px;
    text-align: center;
    font-weight: normal;
  }

  .logout-button {
    margin-top: 24px;
    width: 288px;
  }
}

@media only screen and (min-width: $screen__lg) {
  .application-access-container {
    .icon {
      width: 544px;
      height: 375.6px;
      flex-grow: 0;
      object-fit: contain;
    }

    .head {
      width: 544px;
    }
    .context {
      width: 544px;
    }

    .logout-button {
      margin-top: 24px;
      width: 544px;
    }
  }
}
