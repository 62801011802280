@import "styles/_variables.scss";

.container {
  display: inline-block;
  height: 24px;
  border-radius: 20px;
  padding: 0 12px;
  font-size: 12px;
  line-height: 24px;
  font-weight: 600;
  white-space: nowrap;
}

.clickable {
  cursor: pointer;
}

.green {
  background-color: $moss-green;
  color: $crusoe;
}
.blue {
  background-color: $ice-cold;
  color: $romance;
}
.gray {
  background-color: $alto;
  color: $dove-gray;
}
.red {
  background-color: $wisp-pink;
  color: $monza;
}
.orange {
  background-color: $peach-schnapps;
  color: $red-berry;
}

.purple {
  background-color: $pastel-pink;
  color: $tertiary-dark;
}
