@import "styles/_variables.scss";

.date-container {
  border-bottom: 1px solid $alto;
  display: flex;
  flex-wrap: wrap;
}
// MOBILE DEFAULTS
.absence-period {
  padding-bottom: 32px;
  width: 100%;
}

.rtw-date {
  width: 100%;
  border-top: 1px solid $alto;
  padding: 16px 0 18px 0;
}

.items-not-shown {
  display: flex;
  font-size: 12px;
  justify-content: flex-end;
  
  margin: 0;
}

.fml-associated-case-id {
  padding-bottom: 24px;
}

.icon {
  margin-right: 16px;
}

.link-cases {
  margin-top: 16px;

  p {
    margin: 0;
    font-size: 12px;
  }

  a {
    font-size: 16px;
    font-weight: 600;
  }
}

.arrow {
  margin: 35px 16px 0;
}

.all-dates {
  &__button {
    padding: 0;
    border-radius: 0;
    font-size: 14px;
    font-weight: 600;
  }
}

.footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

// DESKTOP OVERRIDES
@media only screen and (min-width: $screen__lg) {
  .absence-period {
    min-width: 480px;
  }

  .rtw-date {
    width: auto;
    border-top: 0;
    padding: 0 0 0 32px;
  }

  .link-cases {
    p {
      font-size: 14px;
      line-height: 1.4;
    }

    a {
      font-size: 20px;
    }
  }
}
