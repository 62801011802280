@import "styles/_variables.scss";

// MOBILE DEFAULTS
.morneau-tooltip {
  background-color: $mine-shaft !important;
  border-radius: 8px !important;
  color: $white !important;
  opacity: 1 !important;

  padding: 8px !important;
  margin-top: 0 !important;

  font-weight: $font-weight__regular !important;
  font-size: $font-size__mobile !important;
  line-height: $line-height__mobile !important;
}

// DESKTOP OVERRIDES
@media only screen and (min-width: $screen__lg) {
  .morneau-tooltip {
    font-size: $font-size__desktop !important;
    line-height: $line-height__desktop !important;
  }
}
