@import "styles/_variables.scss";

.title-row {
  display: flex;
}

.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: $mine-shaft;
  flex-grow: 1;
  margin: auto 0;
}
