/////////////////////////////////////////
// Color variables
// Unique color names courtesy of:
// http://chir.ag/projects/name-that-color/
/////////////////////////////////////////

$black: #000000;
$white: #ffffff;

$gray: #fafafa;
$wild-sand: #f4f4f7;
$mercury: #e3e6e8;
$alto: #e3e6e8;
$silver-chalice: #b3b9be;
$dove-gray: #686E71;
$mine-shaft: #2c2e30;

$romance: #371e47;
$bahama-blue: #4b286d;
$deep-cerulean: #4b286d;
$cerulean: #4b286d;
$seagull: #7c53a5;
$ice-cold: #d8cbe5;
$white-ice: #f2eff4;

$moss-green: #6abe4d;
$crusoe: #2b8000;

$pastel-pink: #ede9f0;
$peach-schnapps: #fff7ec;
$wisp-pink: #f9e9eb;
$cardinal: #c12335;
$monza: #c12335;
$red-berry: #ffab40;

$violet-eggplant: #4b286d;
$tertiary-dark: #4b286d;

/////////////////////////////////////////
// Default colors
/////////////////////////////////////////

$background-color__page: $white;
$background-color__error: $wisp-pink;
$box-shadow__color: rgba(0, 0, 0, 0.24);
