@import "styles/_variables.scss";

.text-field-container {
  position: relative;
}

.text-field-label {
  position: absolute;
  top: 0;
  left: 16px;
  z-index: 1;
  cursor: text;

  font-size: 16px;

  transition: transform 0.2s, font-size 0.2s;
  transform: translate(0, 17px);
}

.text-field-label--shrink,
.text-field-input:focus ~ .text-field-label {
  font-size: 12px;
  transform: translate(0, 7px);
}

.text-field-input {
  box-sizing: border-box;
  position: relative;
  left: 0;
  width: 100%;
  height: 56px;
  padding: 26px 15px 8px 15px;

  font-size: 16px;
  line-height: 22px;
  color: $dove-gray;
  caret-color: $deep-cerulean;

  border-style: solid;
  border-width: 1px;
  border-color: $dove-gray;
  border-radius: 8px;

  &--no-label {
    padding-top: 8px;
  }

  &::placeholder,
  &::placeholder:hover {
    color: transparent;
  }

  &:hover {
    color: $black;
    border-color: $black;

    ~ .text-field-label {
      color: $black;
    }
  }

  &:focus {
    outline: none;
    padding-left: 14px;

    color: $black;

    border-width: 2px;
    border-color: $deep-cerulean;

    ~ .text-field-label {
      color: $deep-cerulean;
    }

    &::placeholder {
      color: $dove-gray;
    }
  }

  &:invalid {
    padding-left: 14px;

    border-width: 2px;
    border-color: $cardinal;

    ~ .text-field-label,
    ~ .text-field-helper-text,
    ~ .text-area-count {
      color: $monza;
    }
  }

  &:disabled {
    border-color: $silver-chalice;
    color: $silver-chalice;
    padding: 17px 15px;

    ~ .text-field-label {
      height: 0px;
      overflow: hidden;
    }
  }

  &[readonly] {
    background-color: $wild-sand;
    border-color: $wild-sand;
    padding: 17px 15px;
    border-width: 1px;

    ~ .text-field-label {
      height: 0px;
      overflow: hidden;
    }
  }
}

.text-field-textarea {
  padding: 26px 15px;

  &:focus {
    padding: 25px 14px;
  }
}

.text-field-helper-text {
  margin-top: 4px;
  padding-left: 15px;

  white-space: pre-wrap;
  font-size: 12px;
  line-height: 17px;
  color: $dove-gray;
}

.text-area-count {
  position: absolute;
  bottom: 5px;
  right: 16px;
  z-index: 1;
  font-size: 12px;

  &-under {
    bottom: 0;
  }
}
