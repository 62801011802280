@import "styles/_variables.scss";

.policy {
  flex: 0 1 33.33%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
  border-right: solid 1px $romance;
  padding-left: 15px;
  padding-right: 16px;
  line-height: 1.4;
  min-width: 77px;

  .name {
    font-size: 10px;
  }

  .days-left {
    font-size: 14px;

    .number {
      font-size: 20px;
      font-weight: 600;
    }
    .hours {
      font-size: 14px;
      font-weight: 600;
    }
  }

  .link {
    color: $romance;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.14;
  }
}

.policy:first-of-type {
  padding-left: 0;
}

.policy:last-of-type {
  border-right: none;
}

@media only screen and (min-width: $screen__lg) {
  .policy {
    padding-left: 32px;
    border-right: solid 1px $white;

    .name {
      font-size: 14px;
    }

    .days-left {
      font-size: 20px;

      .number {
        font-size: 34px;
      }
      .hours {
        font-size: 20px;
      }
    }

    .link {
      font-size: 16px;
      line-height: 1.13;
    }
  }
}
