@import "styles/_variables.scss";

.button {
  padding: 12px;
  width: 24px + 12px * 2;
  height: 24px + 12px * 2;
  fill: $mine-shaft;
}

.icon--white {
  fill: white;
}

.icon--black {
  fill: black;
}
