@import "styles/_variables.scss";

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-color: white;
  border-width: 1px;
  border-style: solid;

  // Equalize the height of all cards in the same row
  height: 100%;
  box-sizing: border-box;
}
.card-link:hover > .card {
  box-shadow: 0 0 8px 0 rgba(black, 0.32);
}
.card-link:focus > .card {
  border-color: $romance;
}

.card-link {
  text-decoration: none;
  outline: none;
}

.title-wrapper {
  margin-bottom: 20px;
}

.title {
  font-size: 20px;
  font-weight: 600;
  color: $mine-shaft;
  margin: 0;
}

.tag-row {
  > span {
    margin-right: 8px;
    margin-bottom: 8px; // Spacing between rows in case tags wrap
  }
}

.dates-container-wrapper {
  margin-top: 16px;
}

.dates-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

.date-label {
  font-size: 14px;
  color: $dove-gray;
  margin: 0;
}

.time-label {
  font-size: 14px;
  color: $dove-gray;
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip {
  font-size: 14px;
  max-width: 256px;
}

.moon {
  fill: $dove-gray;
}
