@import "styles/_variables.scss";

$menu-width: 256px;

// MOBILE

.button-link {
  display: flex;
  cursor: pointer;
  padding: 0;

  background: none;
  border: none;
}

.layout {
  min-height: 100vh;
  width: 100%;

  position: relative;

  background: $gray;
}

.menu {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 16;
  width: $menu-width;

  left: -100%;
  transition: left 0.25s;

  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  background: linear-gradient(to bottom, $romance, $deep-cerulean);
  color: white;
  box-sizing: border-box;

  &-open {
    left: 0;
    box-shadow: 0 0 8px $box-shadow__color;
  }
}

.cover {
  transition: all 0.25s;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 15;

  background-color: $mine-shaft;
  opacity: 0;

  &-visible {
    visibility: visible;
    opacity: 0.7;
  }
}

.close-button {
  margin-bottom: 28px;
}

.top-navbar,
.top-navbar-with-border {
  position: sticky;
  top: 0;
  height: $standard-navbar-height--mobile;
  z-index: 10;
  display: flex;
  align-items: center;

  background: white;
}

.top-navbar-with-border {
  border-bottom: 1px solid #dedede;
}

.content {
  flex-grow: 1;
}

.nav-content::-webkit-scrollbar {
  display: none; /* Chrome, Sarafi and Opera */
}

.nav-links {
  display: inline-block;

  list-style: none;
  padding: 0;
  width: 100%;
  margin-top: 32px;

  a,
  .button-link {
    padding: 16px;
    width: 100%;
    box-sizing: border-box;

    display: flex;
    font-size: 16px;
    line-height: 22px;
    font-weight: $font-weight__semi-bold;

    color: white;
    text-decoration: none;

    &:hover,
    &:visited,
    &:active {
      color: white;
    }
  }

  a.current-route {
    background: $ice-cold;
    color: $romance;

    svg {
      fill: $romance;
    }
  }

  > li {
    position: relative;

    > a.current-route::before {
      content: "";
      position: absolute;
      width: 2px;
      border-radius: 2px;
      background: $romance;
      left: 0;
      top: 12px;
      bottom: 12px;
    }
  }
}

.nav-icon {
  margin-right: 20px;
  fill: white;
}

.logo {
  margin: 0 auto;
  color: $white;
  width: 112px;
  padding: 0;
  text-align: center;
  font-size: 20px;
  font-weight: $font-weight__semi-bold;
  line-height: 1.4;
}

// DESKTOP

@media only screen and (min-width: $screen__lg) {
  .flex-wrapper {
    display: flex;
  }

  .content {
    margin-left: $menu-width;
  }

  .top-navbar {
    height: $standard-navbar-height--desktop - 1px;
    border-bottom: 1px solid $alto;
  }

  .logo {
    width: 225px;
    height: 48px;
    margin: 16px 16px 0 16px;
  }

  .menu {
    position: fixed;
    left: 0;
    overflow-y: auto;
    -ms-overflow-style: none; /* hidden scrolling for IE and Edge */
    scrollbar-width: none; /* hidden scrolling for Firefox */

    .primary-action-button {
      width: $menu-width - 32px - 32px;
      margin: 40px 32px 0 32px;
      background: white;
      color: $deep-cerulean;
      box-sizing: border-box;

      &:hover {
        color: $romance;
        background-color: $white-ice;
      }
    }
  }

  .menu::-webkit-scrollbar {
    display: none; /* hidden scrolling for Chrome, Safari, and Opera */
  }

  .main {
    flex-grow: 1;
  }
}
