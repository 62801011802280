@import "styles/_variables.scss";

.pagination {
  &__container {
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  &__items {
    letter-spacing: 1px;
  }

  &__button {
    fill: $mine-shaft;
    padding: 0;

    &--prev {
      margin-left: 8px;
    }
  }

  &__page-number {
    color: $mine-shaft;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &--active {
      color: $white;
      background-color: $deep-cerulean;
      border-radius: 50%;
    }
  }
}
