@import "styles/_variables.scss";

.doc-upload {
  margin-top: 24px;
  &-button {
    display: flex;
    align-items: center;
    margin-top: 16px;

    &--icon {
      margin-right: 4px;
      fill: $deep-cerulean;
      width: 16px;
      height: 16px;
    }
  }
}

.doc-upload-modal {
  padding: 16px;
  //min-width: 600px;
  font-size: 20px;

  .doc-upload-filepond {
    &-uploading {
      display: none !important;
    }
    &-files {
      display: none;
    }
  }
  .doc-upload-files-desc {
    padding: 16px 0;
    font-size: 14px;
    line-height: 140%;
    color: $mine-shaft;
  }

  .doc-upload-content {
    margin-top: 16px;
    max-height: 50vh;
    overflow-y: auto;

    .doc-upload-desc {
      padding: 16px 4px;
      line-height: 140%;
      color: $mine-shaft;
    }

    .doc-upload-file {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin: 16px 0 8px 0;
      font-weight: 600;
      font-size: 16px;
      color: $mine-shaft;

      &-state {
        color: $crusoe;
      }
      &-error {
        color: $monza;
      }
    }
  }
  .doc-upload-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    button {
      margin: 8px;
      font-size: 16px;
    }
  }
  .doc-upload-error {
    margin: 10px 0;
  }
}

// DESKTOP OVERRIDES
@media only screen and (min-width: $screen__lg) {
  .doc-upload-modal {
    min-width: 600px;

    .doc-upload-filepond {
      &-files {
        display: block;
      }
    }
  }
}
