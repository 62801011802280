@import "styles/_variables.scss";

$button__box-shadow__color: rgba(66, 114, 135, 0.5);

$button__box-shadow: 0 0 8px $button__box-shadow__color;
$button__box-shadow__hover: 0 0 16px $button__box-shadow__color;
$button__box-shadow__focus: 0 0 0 1px $white, 0 0 0 3px $deep-cerulean,
  0 0 8px $button__box-shadow__color;

// Styles shared between all button implementations
.btn {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border: none;
  transition: 0.2s all ease-in-out;
  border-radius: 32px;
  font-weight: $font-weight__semi-bold;

  // Responsive properties -----------------------------
  padding: 7px 16px;
  font-size: 14px;
  line-height: 18px;

  @media only screen and (min-width: $screen__lg) {
    padding: 11px 16px;
    font-size: 16px;
    line-height: 18px;
  }
  // ---------------------------------------------------

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

// Styles specific for the "Contained" button type
.btn--contained {
  background-color: $deep-cerulean;
  color: $white;

  &:not(:disabled) {
    box-shadow: $button__box-shadow;

    &:hover {
      box-shadow: 0 0 8px 0 rgba($deep-cerulean, 0.48);
      background-color: $bahama-blue;
    }

    &:focus {
      box-shadow: $button__box-shadow__focus;
    }

    &:active {
      background-color: $romance;
    }
  }

  &:disabled {
    background-color: $wild-sand;
    color: $silver-chalice;

    &.readonly {
      color: $dove-gray;
    }
  }
}

// Specific styles for the "Outlined" button type
.btn--outlined {
  background-color: $white;
  color: $deep-cerulean;
  border: 1px solid $silver-chalice;

  &:not(:disabled) {
    &:hover {
      background-color: rgba($deep-cerulean, 0.04);
    }

    &:focus {
      background-color: rgba($deep-cerulean, 0.12);
      color: $romance;
      border: 1px solid $romance;
    }

    &:active {
      background-color: rgba($deep-cerulean, 0.24);
    }
  }

  &:disabled {
    color: $silver-chalice;

    &.readonly {
      color: $dove-gray;
    }
  }
}

// Styles specific for the "Icon" button type
.btn--icon {
  transition: background-color 0.3s ease-in-out;
  border-radius: 100%;
  background: none;
  fill: $deep-cerulean;
  line-height: 0;
  font-size: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:not(:disabled) {
    &:hover {
      background: rgba($deep-cerulean, 0.04);
    }

    &:focus {
      background: rgba($deep-cerulean, 0.04);
    }

    &:active {
      background-color: rgba($deep-cerulean, 0.24);
    }
  }

  &:disabled {
    fill: $alto;
  }
}

// Styles specific for the "Text" button type
.btn--text {
  color: $deep-cerulean;
  text-decoration: underline;
  background: none;

  &:not(:disabled) {
    &:hover {
      background: rgba($deep-cerulean, 0.04);
    }

    &:focus {
      background: rgba($deep-cerulean, 0.12);
      border: 1px solid $romance;
      color: $romance;
    }

    &:active {
      background-color: rgba($deep-cerulean, 0.24);
    }
  }

  &:disabled {
    color: $silver-chalice;
    text-decoration: none;

    &.readonly {
      color: $dove-gray;
    }
  }
}
