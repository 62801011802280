@import "styles/_variables.scss";

.container {
  display: flex;
  overflow-x: auto;
  margin-top: 32px;
  margin-bottom: -$spacing--mobile;
  &::-webkit-scrollbar {
    height: 0;
    background: transparent;
  }
}

.tab-entry {
  font-size: 14px;
  font-weight: 600;
  padding-right: 32px;
  cursor: pointer;
  white-space: nowrap;

  &-bottom {
    display: flex;
    justify-content: center;
    & > div {
      margin-top: 18px;
      border-radius: 2px;
      width: 40px;
      height: 4px;
    }
  }

  &-active {
    color: $cerulean;

    .tab-entry-bottom {
      & > div {
        background: $cerulean;
      }
    }
  }
}

// DESKTOP OVERRIDES
@media only screen and (min-width: $screen__lg) {
  .container {
    margin-bottom: -$spacing--desktop;
  }

  .tab-entry {
    font-size: 16px;

    &-bottom {
      & > div {
        margin-top: 24px;
      }
    }
  }
}
