@import "styles/_variables.scss";

.table {
  font-size: 14px;
  font-weight: 400;
  border-spacing: 0;
  border-collapse: collapse;
  color: $mine-shaft;
  width: 100%;

  th {
    font-weight: 600;
  }

  &-header {
    background-color: $wild-sand;
    border-bottom: 1px solid $dove-gray;
  }

  &-body {
    &__row {
      &:not(:last-child) {
        border-bottom: 1px solid $alto;
      }
    }
  }

  &-cell {
    padding: 16px;

    &--left {
      text-align: left;
    }

    &--right {
      text-align: right;
    }

    &--center {
      text-align: center;
    }
  }

  &-footer {
    background-color: $wild-sand;
    border-top: 1px solid $dove-gray;
    font-weight: 600;
  }

  &-mobile {
    display: flex;
    flex-direction: column;

    &__cell {
      padding: 16px 0 15px;
      border-bottom: 1px solid $alto;
      line-height: 1.4;
      font-size: 12px;
      color: $dove-gray;
    }

    &__header {
      font-size: 14px;
      font-weight: 600;
      color: $mine-shaft;
      margin-bottom: 4px;
    }

    &__lower-header {
      display: flex;
      align-items: center;
    }

    &__footer {
      margin-top: 16px;
    }
  }
}
