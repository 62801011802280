@import "styles/_variables.scss";

.title-row {
  display: flex;
}

.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: $mine-shaft;
  flex-grow: 1;
  margin: auto 0;
}

.countdown-timer {
  font-size: 34px;
  font-weight: 600;
  line-height: 1.4;
  color: $mine-shaft;
}

.button-row {
  display: flex;
  justify-content: flex-end;

  & button {
    margin-left: 8px;
  }
}
