@import "styles/_variables.scss";

.api-error-cases {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .icon {
    width: 192px;
    height: 142px;
    margin: 0 48px 24px;
    object-fit: contain;
  }

  .head {
    font-weight: 600;
    margin: 0 0 8px;
  }

  .context {
    margin: 8px 16px 0;
    color: $dove-gray;
    min-width: 288px;
    text-align: center;
    font-weight: normal;
  }

  &.report-absence {
    height: 100vh;
  }
}

@media only screen and (min-width: $screen__lg) {
  .api-error-cases {
    .icon {
      width: 352px;
      height: 261px;
      margin: 0 96px 40px;
      object-fit: contain;
    }

    .context {
      max-width: 544px;
    }
  }
}
