

$font-family__sans-serif: "Helvetica", sans-serif;
$font-url__otf: url('../../fonts/OTF/HelveticaNowTextSA.otf') format('opentype');
$font-url__otf-bold: url('../../fonts/OTF/HelveticaNowTextSABold.otf') format('opentype');
$font-url__otf-medium: url('../../fonts/OTF/HelveticaNowTextSAMedium.otf') format('opentype');
$font-url__woff: url('../../fonts/WOFF/HelveticaNowTextSA.woff') format('woff');
$font-url__woff2: url('../../fonts/WOFF/HelveticaNowTextSA.woff2') format('woff2');
$font-url__woff-bold: url('../../fonts/WOFF/HelveticaNowTextSABold.woff') format('woff');
$font-url__woff2-bold: url('../../fonts/WOFF/HelveticaNowTextSABold.woff2') format('woff2');
$font-url__woff-medium: url('../../fonts/WOFF/HelveticaNowTextSAMedium.woff') format('woff');
$font-url__woff2-medium: url('../../fonts/WOFF/HelveticaNowTextSAMedium.woff2') format('woff2');

@font-face {
  font-family: $font-family__sans-serif;
  src: $font-url__otf,
       $font-url__otf-bold,
       $font-url__otf-medium,
       $font-url__woff,
       $font-url__woff2,
       $font-url__woff-bold,
       $font-url__woff2-bold,
       $font-url__woff-medium,
       $font-url__woff2-medium;
}

// Default font sizes
$font-size__mobile: 14px;
$line-height__mobile: 20px;

$font-size-small__mobile: 12px;
$line-height-small__mobile: 18px;

$font-size__desktop: 16px;
$line-height__desktop: 22px;

$font-size-small__desktop: 14px;
$line-height-small__desktop: 20px;

// Weights
$font-weight__light: 300;
$font-weight__regular: 400;
$font-weight__semi-bold: 600;
$font-weight__bold: 700;

// Colors
$text-color: $dove-gray;
$text-color__intense: $black;
$text-color__error: $cardinal;
$text-color__link: $deep-cerulean;
$text-color__link__hover: $romance;

/////////////////////////////////////////
// Headings
/////////////////////////////////////////

$h1__font-size__mobile: 24px;
$h1__line-height__mobile: 34px;
$h1__font-size__desktop: 48px;
$h1__line-height__desktop: 67px;

$h2__font-size__mobile: 20px;
$h2__line-height__mobile: 28px;
$h2__font-size__desktop: 34px;
$h2__line-height__desktop: 48px;

$h3__font-size__mobile: 16px;
$h3__line-height__mobile: 22px;
$h3__font-size__desktop: 24px;
$h3__line-height__desktop: 34px;

$h4__font-size__mobile: 14px;
$h4__line-height__mobile: 20px;
$h4__font-size__desktop: 20px;
$h4__line-height__desktop: 28px;
