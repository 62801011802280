@import "styles/_variables.scss";

// MOBILE DEFAULTS
.separator {
  border: none;
  border-top: 1px solid $alto;
  margin: $spacing--mobile 0;
}

.back-button {
  width: 24px + 16px * 2;
  height: 24px + 16px * 2;
}

.back-button-icon {
  fill: $deep-cerulean;
}

.tag-row {
  > span {
    margin-right: 8px;
  }
}

.mini-header {
  color: $mine-shaft;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.4;
  margin: 0 0 16px 0;
}

.left-and-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 486px;
}

.case-id-container {
  margin-bottom: 24px;
}

.text-button {
  font-size: 12px;
}

.fineprint {
  font-size: 12px;
  
}

.page-title {
  font-size: 20px;
  line-height: 28px;
}

.doc-upload {
  margin-top: 24px;
  &-button {
    display: flex;
    align-items: center;
    margin-top: 16px;

    &--icon {
      margin-right: 4px;
      fill: $deep-cerulean;
      width: 16px;
      height: 16px;
    }
  }
}

.doc-upload-modal {
  padding: 8px;
  .filepond--credits {
    display: none !important;
  }
}

// DESKTOP OVERRIDES
@media only screen and (min-width: $screen__lg) {
  .button-link {
    display: flex;
    cursor: pointer;
    padding: 0;

    background: none;
    border: none;
  }

  .back-button {
    width: auto;
    height: 40px;
    margin-left: $spacing--desktop;
    padding: 11px;

    display: flex;
    align-items: center;
  }

  .back-button-icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }

  .scrollable-pane {
    padding: $spacing--desktop;
  }

  .separator {
    margin: $spacing--desktop 0;
  }

  .header-info-section {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }

  .mini-header {
    font-size: 16px;
  }

  .flexible-decisions {
    display: flex;
    flex-wrap: wrap;
    max-width: 992px;
  }

  .flexible-block {
    min-width: 350px;
    margin-right: 32px;
    flex: auto 1 1;
  }

  .text-button {
    font-size: 14px;
  }

  .page-title {
    font-size: 24px;
    line-height: 34px;
  }
}
