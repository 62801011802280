@import "styles/_variables.scss";

.error-message {
  display: flex;
  flex-direction: row;
  background: $wisp-pink;
  color: $cardinal;
  padding: 16px;
  border-radius: 8px;
}

.error-icon {
  flex: 0 0 auto;
  margin-right: 8px;
  fill: $cardinal;
}
