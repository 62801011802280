@import "styles/_variables.scss";

.select-container {
  position: relative;
}

.select {
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;

  width: 100%;
  height: 56px;
  border-radius: 8px;
  border: solid 1px $dove-gray;
  padding: 26px 40px 8px 15px;

  &--no-label {
    padding-top: 8px;
  }

  &-label {
    position: absolute;
    top: 0;
    left: 16px;
    right: 40px;
    z-index: 1;
    overflow: hidden;

    height: 17px;
    font-size: 12px;
    color: $dove-gray;
    transform: translate(0, 7px);
    text-overflow: ellipsis;
  }

  &-value {
    font-size: 16px;
    color: $mine-shaft;
  }

  &-icon {
    position: absolute;
    right: 16px;
    top: 16px;
  }

  &:hover {
    color: $black;
    border-color: $black;

    ~ .select-label {
      color: $black;
    }
  }

  &:focus {
    outline: none;
    padding-left: 14px;

    color: $black;

    border-width: 2px;
    border-color: $deep-cerulean;

    ~ .select-label {
      color: $deep-cerulean;
    }

    &::placeholder {
      color: $dove-gray;
    }
  }

  &:invalid {
    padding-left: 14px;

    border-width: 2px;
    border-color: $cardinal;

    ~ .select-label,
    ~ .select-helper-text {
      color: $monza;
    }
  }

  &:disabled {
    border-color: $silver-chalice;
    color: $silver-chalice;
    padding: 17px 15px;

    ~ .select-label {
      height: 0px;
      overflow: hidden;
    }
  }

  &.readonly {
    border-color: $dove-gray;
    border-width: 1px;
    cursor: default;

    & .select-value {
      color: $dove-gray;
    }
  }
}

.options-shadow {
  display: none;
  background-color: rgba(33, 33, 33, 0.7);
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 50vh;
  left: 0;
  right: 0;
  z-index: 12;
}

.options-container {
  position: absolute;
  width: 100%;
  color: $dove-gray;
  background-color: $white;
  z-index: 5;
}

.options-header {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  width: 100%;
}

.options-header-text {
  color: $mine-shaft;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 16px;
  width: calc(100% - 60px);
  height: 40px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.close-button {
  svg {
    fill: $mine-shaft;
  }
}

.options-list {
  list-style: none;
  position: absolute;
  width: 100%;
  padding: 8px 0;
  margin: 0;

  border-radius: 8px;
  box-shadow: 0 0 8px 0 $box-shadow__color;
  background-color: $white;
}

.option {
  cursor: pointer;

  padding: 12px 16px;

  &:hover {
    background-color: rgba($white-ice, 0.5);
    color: $black;
  }

  &:focus,
  &[aria-selected="true"] {
    outline: none;
    background-color: $white-ice;
    color: $black;
  }
}

@media only screen and (max-width: $screen__m) {
  .options-shadow {
    display: block;
  }

  .options-container {
    position: fixed;
    top: 50vh !important;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 12;
  }

  .options-header {
    display: flex;
  }

  .options-list {
    top: 44px !important;
    bottom: 0;
    overflow-y: auto;
    box-shadow: none;
  }
}

$item-height: 12px + 22px + 12px;

// TABLET OR DESKTOP
@media only screen and (min-width: $screen__m) {
  .select-container {
    font-size: 14px;
  }

  .options-list {
    // Attempt to cut the last item in half as a hint that scrolling is available
    max-height: $item-height * 5.4;
    overflow-y: auto;
  }
}
