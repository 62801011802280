@import "styles/variables/_colors.scss";

.policy-summary {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: $romance;
  line-height: 1.4;
  letter-spacing: normal;

  .title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .arrow-left,
  .arrow-right {
    flex: 0 0 80px;
    display: flex;
    flex-direction: column;

    justify-content: center;

    .icon {
      fill: $romance;
    }
  }

  .arrow-left {
    align-items: flex-start;
  }

  .arrow-right {
    align-items: flex-end;
  }

  .policies-carousel {
    flex: 1 1;
    display: flex;
    flex-direction: row;

    overflow-x: auto;
    overscroll-behavior: contain;
    scroll-snap-type: x mandatory;
  }

  .policies-chunk {
    flex: 0 0 100%;
    display: flex;
    scroll-snap-align: start;
  }

  // Hack to hide scrollbar in various browsers
  .policies-carousel {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .policies-carousel::-webkit-scrollbar {
    display: none;
  }
}
