@import "styles/_variables.scss";

.policy-summary-mobile {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: $romance;
  line-height: 1.4;
  letter-spacing: normal;
  padding: 24px;
  margin-bottom: 24px;
  border-radius: 8px;
  background-color: $ice-cold;

  .title {
    font-size: 14px;
    font-weight: 600;
  }

  .policies {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overscroll-behavior: contain;
  }
}

// Hack to hide scrollbar in various browsers
.policies {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.policies::-webkit-scrollbar {
  display: none;
}
