@import "styles/_variables.scss";

// MOBILE DEFAULTS

.icon {
  width: 32px;
  height: 32px;

  &-light {
    fill: white;
  }

  &-dark {
    fill: $romance;
  }
}

.user-widget {
  margin-left: 16px;

  display: flex;
  align-items: center;
}
.user-lang{
  margin-right: 12px;
}
.user-image {
  width: 48px;
  height: 48px;
  margin-right: 16px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &-bg-dark {
    // dark background for light theme
    background: linear-gradient(to bottom, $romance, $deep-cerulean);
  }

  &-bg-light {
    // light background for dark theme
    background: linear-gradient(to bottom, $seagull, $white-ice);
  }
}

.user-name {
  font-size: 16px;
}

.user-role {
  font-size: 14px;
}

// DESKTOP OVERRIDES

@media only screen and (min-width: $screen__lg) {
  .user-widget {
    flex: auto 0 0;
    margin: 0 32px 0 auto;
  }
}

// THEMING

.user-widget--light {
  .user-name {
    color: black;
  }
  .user-role {
    color: $dove-gray;
  }
}

.user-widget--dark {
  .user-name {
    color: $ice-cold;
  }
  .user-role {
    color: $wild-sand;
  }
}
