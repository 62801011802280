@import "styles/_variables.scss";

// MOBILE DEFAULTS
.container {
  max-width: 448px;
  margin-bottom: 16px;
}

.date-label-container {
  display: flex;
  align-items: center;
  max-width: 360px;
  padding-top: 16px;
}

.title-wrapper {
  margin-bottom: 20px;
}

.title {
  font-size: 20px;
  font-weight: 600;
  color: $mine-shaft;
  margin: 0;
}

.arrow {
  width: 16px;
  height: 16px;
  margin: 0 16px;
  fill: $dove-gray;
}

// DESKTOP OVERRIDES
@media only screen and (min-width: $screen__lg) {
  .container {
    margin-bottom: 24px;
  }

  .arrow {
    width: 24px;
    height: 24px;
  }
}
