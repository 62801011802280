@import "styles/_variables.scss";

.pagination {
  &-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    margin-bottom: 158px;
  }
}

.date-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__date-separator {
    margin-right: 16px;
  }

  &__moon-icon {
    fill: $dove-gray;

    &--desktop {
      height: 16px;
    }
  }

  &-lower {
    &__time-icon {
      width: 16px;
      height: 16px;
      fill: $dove-gray;
      margin-right: 4px;
    }

    &__date-separator {
      margin: 0 7px 0 8px;
    }
  }
}
