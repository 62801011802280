@import "styles/_variables.scss";

.filepond--credits {
  display: none !important;
}

.filepond--wrapper {
  padding: 32px;
  height: 100px;
}

.filepond--list-scroller,
.filepond--panel-root {
  display: none;
}

.filepond--drop-label {
  background-color: #ffffff;
  height: auto !important;
}

.drop-label-content {
  width: 100%;
  height: 100%;

  .drop-label-text {
    margin-top: 24px;
    font-size: 20px;
  }

  .browse-btn {
    background: $deep-cerulean;
    box-shadow: 0px 0px 8px rgba(0, 120, 172, 0.48);
    border-radius: 100px;
    font-weight: 600;
    padding: 11px 16px;
    text-decoration: none;
    display: flex;
    align-items: center;
    text-align: center;

    span {
      font-size: 16px;
      line-height: 18px;
      color: #ffffff;
    }
  }
}

@media only screen and (min-width: $screen__lg) {
  .filepond--wrapper {
    border: 2px dashed #0078ab;
    border-radius: 8px;
    height: 200px;
  }
}
