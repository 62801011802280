@import "styles/_variables.scss";

$card-width: 352px;

.to-do-activities {
  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin: -5px;
    position: relative;
    z-index: 1;
    transition: all 0.5s ease-in-out;
  }

  &__claim-card {
    cursor: pointer;

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    font-size: 12px;
  }

  &__expand-button {
    font-size: 12px;
    padding: 0;
    border-radius: 0;

    &:not(:disabled):focus {
      border: none;
    }
  }

  &__items-not-shown {
    
  }
}

@media only screen and (min-width: $screen__m) {
  .to-do-activities {
    &__wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fit, $card-width);
      grid-auto-rows: auto;
      grid-gap: 32px;
    }

    &__claim-card {
      margin-bottom: 0;

      &:not(:last-of-type) {
        margin-bottom: 0;
      }
    }
  }
}
