@import "styles/_variables.scss";

// MOBILE DEFAULTS
.label {
  line-height: 22px;
  font-size: $font-size-small__mobile;
  font-weight: $font-weight__regular;
  color: $dove-gray;
  min-height: 28px;
}

.value {
  font-size: 16px;
  line-height: 22px;
  font-weight: $font-weight__semi-bold;
  color: $mine-shaft;
  margin: 0;
}

.separator {
  text-align: center;
  margin: 0 16px;
  align-self: center;
}

.summary-label-container {
  align-self: flex-start;
}

.warning-text-date {
  font-weight: 600;
}

.absence-duration {
  display: flex;
  align-items: center;
  padding-top: 16px;

  p {
    margin: 0;
  }

  .absence-time {
    padding-right: 20px;
  }

  .unpaid-absence-time {
    padding-left: 20px;
    border-left: 2px solid $dove-gray;
  }

  .time-icon {
    fill: $dove-gray;
    padding-right: 6px;
  }
}

// DESKTOP OVERRIDES
@media only screen and (min-width: $screen__lg) {
  .label {
    font-size: $font-size__desktop;
    line-height: 28px;
    color: $dove-gray;
  }

  .value {
    font-size: 20px;
    line-height: 28px;
    color: $mine-shaft;
  }

  .separator {
    font-size: 24px;
    margin: 0 32px;
  }

  .summary-label-container {
    width: auto;
  }
}
