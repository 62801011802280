@import "styles/_variables.scss";

$welcome-box-min-width: 320px;

.dashboard-page {
  h2 {
    font-size: 16px;
    line-height: 22px;
    font-weight: $font-weight__semi-bold;
  }
}

.blank-space {
  height: 24px;
  width: 24px;
  padding: 8px;
  margin-right: 8px;
}

.welcome-container {
  padding: 16px 0;
  margin-bottom: 24px;
  border-radius: 8px;
  background-color: $gray;
  display: flex;
  flex-direction: row;
}

.welcome-box {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  flex: 1 0 30%;

  h1 {
    color: $cerulean;
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: $font-weight__regular;
  }

  .welcome-name {
    color: $romance;
    font-size: 24px;
    line-height: 34px;
    font-weight: $font-weight__semi-bold;
  }

  .welcome-image {
    position: absolute;
    right: 24px;
  }
}

.policy-summary-box {
  flex: 1 0 70%;
  max-width: calc(100% - #{$welcome-box-min-width});
}

.policy-summary-box-mobile {
  width: 100%;
}

.report-absence {
  width: 100%;
  margin-bottom: 24px;
}

.card {
  display: inline-block;
  border-radius: 8px;
  background-color: white;
  width: 100%;
  height: 175px;
}

.logo {
  margin: 0 auto;
  width: 112px;
  text-align: center;
  font-size: 20px;
  font-weight: $font-weight__semi-bold;
  line-height: 1.4;
}

.menu-button {
  padding: 8px !important;
  margin-left: 8px;
}

.empty-cases {
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    min-width: 192px;
    margin: 0 96px 40px;
  }

  .head {
    font-weight: 600;
    margin: 0;
  }

  .context {
    margin: 8px 0 0;
    color: $dove-gray;
    min-width: 288px;
    text-align: center;
    font-weight: normal;
  }
}

@media only screen and (min-width: $screen__lg) {
  .dashboard-page {
    h2 {
      font-size: 24px;
      line-height: 34px;
      font-weight: $font-weight__semi-bold;
    }
  }

  .welcome-container {
    padding: 28px 24px;
    background-color: $ice-cold;
  }

  .welcome-box {
    min-width: 320px;

    h1 {
      font-size: 20px;
      line-height: 1.4;
      color: $romance;
    }

    .welcome-name {
      font-size: 40px;
      line-height: 56px;
    }
  }

  .card {
    width: 250px;
  }

  .empty-cases {
    .icon {
      min-width: 352px;
    }

    .context {
      max-width: 544px;
    }
  }
}
