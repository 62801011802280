@import "./variables";

body {
  font-family: $font-family__sans-serif;
  color: $text-color;
  margin: 0;

  font-size: $font-size__mobile;
  line-height: $line-height__mobile;

  min-width: 320px;
}

p {
  margin-bottom: $line-height__mobile;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family__sans-serif;
  font-weight: $font-weight__semi-bold;
  color: $text-color__intense;
}

h1 {
  font-size: $h1__font-size__mobile;
  line-height: $h1__line-height__mobile;
}

h2 {
  font-size: $h2__font-size__mobile;
  line-height: $h2__line-height__mobile;
}

h3 {
  font-size: $h3__font-size__mobile;
  line-height: $h3__line-height__mobile;
}

h4 {
  font-size: $h4__font-size__mobile;
  line-height: $h4__line-height__mobile;
}

a,
a:visited {
  color: $text-color__link;
  text-decoration: underline;
  cursor: pointer;
}

a:hover {
  color: $text-color__link__hover;
}

button,
input,
textarea {
  font-family: $font-family__sans-serif;
  font-size: $font-size__mobile;
  line-height: $line-height__mobile;
}

input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

// Desktop
@media only screen and (min-width: $screen__lg) {
  body {
    font-size: $font-size__desktop;
    line-height: $line-height__desktop;
  }

  p {
    margin-bottom: $line-height__desktop;
  }

  h1 {
    font-size: $h1__font-size__desktop;
    line-height: $h1__line-height__desktop;
  }

  h2 {
    font-size: $h2__font-size__desktop;
    line-height: $h2__line-height__desktop;
  }

  h3 {
    font-size: $h3__font-size__desktop;
    line-height: $h3__line-height__desktop;
  }

  h4 {
    font-size: $h4__font-size__desktop;
    line-height: $h4__line-height__desktop;
  }

  button,
  input,
  textarea {
    font-size: $font-size__desktop;
    line-height: $line-height__desktop;
  }
}

.app-header {
  margin: 10px;
}

.d-flex {
  display: flex;
}
