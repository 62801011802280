@import "styles/_variables.scss";

.title-row {
  display: flex;
  align-items: flex-start;
}

.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: $mine-shaft;
  flex-grow: 1;
  margin: auto 0;
  width: calc(100% - 48px);
}

.button-row {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;

  & button {
    margin-left: 8px;
  }
}

.logout-button {
  padding: 0;
  width: 24px;
  height: 24px;
  margin-left: 24px;
}

@media only screen and (min-width: $screen-min-width) {
  .title-row {
    align-items: center;
  }
}
