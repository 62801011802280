@import "styles/_variables.scss";

$card-width: 352px;

.visible {
  visibility: visible;
}
.invisible {
  visibility: hidden;
}

// MOBILE DEFAULTS

.tag-filter-row {
  display: flex;
  overflow-x: auto;
  margin-top: $spacing--mobile;
  padding: 1px;

  > * {
    margin-right: 8px;
  }

  &:last-child {
    margin-right: 0;
  }
}

.card-list {
  display: flex;
  flex-direction: column;
}

.claim-card {
  margin-bottom: 16px;
}

.page-title {
  font-size: 16px;
  line-height: 22px;
}
.empty-cases {
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    max-height: 142px;
    margin: 0 96px 40px;
  }

  .head {
    font-weight: 600;
    margin: 0;
  }

  .context {
    margin: 8px 0 0;
    color: $dove-gray;
    min-width: 288px;
    text-align: center;
    font-weight: normal;
  }
}

// DESKTOP OVERRIDES

@media only screen and (min-width: $screen__lg) {
  .page-title {
    margin-top: 0;
    font-size: 24px;
    line-height: 34px;
  }

  .tag-filter-row {
    margin-top: $spacing--mobile;
  }

  .card-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, $card-width);
    grid-auto-rows: auto;
    grid-gap: 32px;
  }

  .claim-card {
    width: $card-width;
    margin: 0;
  }

  .empty-cases {
    .icon {
      min-height: 260px;
    }

    .context {
      max-width: 544px;
    }
  }
}
