@import "styles/_variables.scss";

// MOBILE DEFAULTS
.claim-hours-row {
  display: block;
  margin-bottom: 14px;
}

.claim-hours-description {
  font-size: 14px;
  color: $mine-shaft;
  margin-bottom: 16px;
}

.claim-hours-label {
  font-size: 14px;
  color: $mine-shaft;
  font-weight: 600;
  margin-bottom: 8px;
}

.hours-block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 380px;
  padding: 2px 24px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: $alto;
  > p {
    margin: 0;
    word-wrap: break-word;
  }
}

.hours-used, .hours-planned {
  margin-bottom: 16px;
}

.hours-planned-wrapper, .hours-used-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  flex-wrap: nowrap;
}

.hours-info {
  display: flex;
  align-items: baseline;
  gap: 8px;
  flex-wrap: nowrap;
}

.hours-extra {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  gap: 4px;
  width: auto;
  margin-left: 0;
  text-align: left;
}



.separator {
  display: inline-block;
  vertical-align: middle;
  height: 40px;
  border-left: 1px solid #c5afdb;
  margin: 0 10px;
}

.hour-value {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
  width: auto;
  text-align: left;
}

.week-value {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
  width: auto;
  text-align: right;
}

.hour-label {
  font-size: 14px;
  color: $dove-gray;
  white-space: nowrap;
}

.hours-planned {
  background-color: $ice-cold;
  color: $romance;
}

.hours-used {
  background-color: $alto; 
  color: $dove-gray;
}

// DESKTOP OVERRIDES
@media only screen and (min-width: $screen__lg) {
  .claim-hours-description {
    font-size: 16px;
  }

  .claim-hours-label {
    font-size: 16px;
  }

  .hour-value {
    font-size: 34px;
  }

  .week-value {
    font-size: 34px;
  }

  .hour-label {
    font-size: 16px;
  }

  .hours-used, .hours-planned {
    margin-right: 32px;
  }
}
