@import "styles/_variables.scss";

// MOBILE DEFAULTS
.start-date-container {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.icon {
  margin-right: 16px;
}

// DESKTOP OVERRIDES
@media only screen and (min-width: $screen__lg) {
  .icon {
    margin-right: 24px;
  }
}
