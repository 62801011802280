@import "styles/_variables.scss";

.inline-button {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: $text-color__link;
  text-decoration: underline;

  &:hover {
    color: $text-color__link__hover;
  }
}
