@import "styles/_variables.scss";

$bottom-nav-height--mobile: 64px;
$bottom-nav-height--desktop: 96px;

// MOBILE DEFAULTS
.layout {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - #{$standard-navbar-height--mobile});
}

.fixed-pane {
  position: sticky;
  top: $standard-navbar-height--mobile;
  z-index: 9;

  padding: $spacing--mobile;
  border-bottom: 1px solid $alto;
  background: white;

  transition: box-shadow 0.3s;

  &--shadowed {
    box-shadow: 0 0 8px $box-shadow__color;
  }
}

.scrollable-pane {
  flex: auto 1 1;

  padding: $spacing--mobile;
}

.bottom-navbar {
  flex: $bottom-nav-height--mobile 0 0;

  border-top: 1px solid $alto;

  display: flex;
  align-items: center;
  padding: 0 16px 4px 16px;
  box-sizing: border-box;
}

// DESKTOP OVERRIDES
@media only screen and (min-width: $screen__lg) {
  .layout {
    min-height: calc(100vh - #{$standard-navbar-height--desktop});
  }

  .fixed-pane {
    top: $standard-navbar-height--desktop;
    padding: 24px $spacing--desktop $spacing--desktop;
  }

  .scrollable-pane {
    padding: $spacing--desktop;
  }

  .bottom-navbar {
    flex: $bottom-nav-height--desktop 0 0;
    padding: 0 24px 8px 24px;
  }
}
