@import "styles/_variables.scss";

.leave-policy {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  width: 100%;

  .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.4;
    color: $mine-shaft;
  }

  .header {
    font-size: 24px;
  }

  .remaining-time-box {
    display: flex;
    width: 450px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
    padding: 16px;
    border-radius: 8px;
    background-color: $ice-cold;
    .content-text {
      font-size: 14px;
      line-height: 1.4;
      color: $romance;
  
      .remaining {
        font-size: 24px;
        font-weight: 600;
        margin-right: 8px;
      }
  
      .label {
        font-weight: 600;
        margin-bottom: 10px;
      }
  
      .separator {
        display: inline-block;
        vertical-align: sub;
        height: 40px;
        border-left: 1px solid #c5afdb;
        margin: 0 10px;
      } 
    }
  }

  // Box 2: gray-box
  .gray-box {
    display: flex;
    width: 450px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
    padding: 16px;
    border-radius: 8px;
    background-color: $mercury;

    .content-text {
      font-size: 14px;
      line-height: 1.4;
      color: $romance;

      .remaining {
        font-size: 24px;
        font-weight: 600;
        margin-right: 8px;
      }
      .label{
        font-weight: 600;
        margin-bottom: 10px;
      }
      .separator {
        display: inline-block;
        vertical-align: sub;
        height: 40px;
        border-left: 1px solid #c5afdb;
        margin: 0 10px;
      }
    }
  }

  .claims-list-header {
    font-size: 14px;
    line-height: 1.4;
    color: $mine-shaft;
  }

  .claims-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .spinner {
    width: 100%;
  }

  .empty-cases {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .icon {
      max-height: 142px;
      margin: 0 96px 40px;
    }

    .head {
      font-weight: 600;
      margin: 0;
    }

    .context {
      margin: 8px 0 0;
      color: $dove-gray;
      min-width: 288px;
      text-align: center;
      font-weight: normal;
    }
  }
}

@media only screen and (min-width: $screen__lg) {
  .leave-policy {
    gap: 32px;

    .remaining-time-box, .gray-box {
      padding: 20px 24px;

      .content-text {
        font-size: 20px;

        .remaining {
          font-size: 35px;
        }
      }
    }

    .claims-list-header {
      font-size: 20px;
    }

    .claims-list {
      display: grid;
      grid-template-columns: repeat(auto-fit, 352px);
      grid-auto-rows: auto;
      grid-gap: 32px;
    }

    .empty-cases {
      .icon {
        min-height: 260px;
      }

      .context {
        max-width: 544px;
      }
    }
  }
}
